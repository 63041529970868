<template>
  <section class="user-guide-search-results">
    <div v-if="hasSearchResults" class="results-list">
      <user-guide-search-result
        v-for="(result, index) in searchResults"
        :key="index"
        class="results-list__search-result"
        :search-result="result"
      />
    </div>
    <div
      v-else
      class="user-guide-search-results__no-results no-search-results flex-column--align-center"
    >
      <span
        class="no-search-results__icon custom-icon icon-personalize-packaging-no-matching"
      ></span>
      <p class="no-search-results__no-articles">{{ $t('userGuide.label.noArticlesFound') }}</p>
      <p class="no-search-results__different-search different-search-options">
        <i18n path="userGuide.label.tryDifferentSearch" tag="span">
          <router-link
            class="different-search-options__link inline-block mr-2"
            :to="$options.userGuideSearchPageLinks.GLOSSARY_PAGE"
          >
            {{ $t('userGuide.title.glossary') }}
          </router-link>
        </i18n>
      </p>
    </div>
  </section>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';
import UserGuideSearchResult from '@/modules/dashboard/components/user-guide/search/UserGuideSearchResult';

import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';

const USER_GUIDE_SEARCH_PAGE_LINKS = {
  GLOSSARY_PAGE: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.GLOSSARY
  })
};

export default {
  name: 'UserGuideSearchResultsList',
  components: { UserGuideSearchResult },
  userGuideSearchPageLinks: USER_GUIDE_SEARCH_PAGE_LINKS,
  props: {
    searchResults: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasSearchResults() {
      return this.searchResults.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.user-guide-search-results {
  width: calc(100% - 245px);
  min-height: 850px;
  margin-left: 245px;
  padding: 30px;

  background: $white;
  border-radius: 10px;
}

.results-list {
  &__search-result {
    margin-bottom: 20px;
  }
}

.no-search-results {
  padding: 78px 0 64px;

  &__icon {
    margin-bottom: 12px;
  }

  &__no-articles {
    font-size: 15px;
    line-height: 17px;

    color: $grey-color;

    margin-bottom: 20px;
  }

  &__different-search {
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;

    color: $grey-color;
  }
}

.different-search-options {
  &__link {
    color: $active;
    text-decoration: underline;
  }
}

@media (max-width: 767px) {
  .user-guide-search-results {
    position: absolute;
    left: 0;

    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 30px 8px;
    border-radius: 0;
  }

  .results-list {
    padding: 0 22px;
  }

  .no-search-results {
    padding: 0 0 64px;

    &__no-articles,
    &__different-search {
      text-align: center;
    }
  }
}
</style>
