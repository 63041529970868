<template>
  <div class="user-guide-search">
    <user-guide-search-sidebar
      :search-query="searchQuery"
      :search-results-counter="searchResultsCounter"
    />
    <user-guide-search-results-list :search-results="searchResults" />
  </div>
</template>

<script>
import UserGuideSearchSidebar from '@/modules/dashboard/components/user-guide/search/UserGuideSearchSidebar';
import UserGuideSearchResultsList from '@/modules/dashboard/components/user-guide/search/UserGuideSearchResultsList';

import { USER_GUIDE_SEARCH } from '@/modules/dashboard/api/user-guide';
import SearchResult from '@/modules/dashboard/components/user-guide/search/SearchResult';

export default {
  name: 'UserGuideSearch',
  components: { UserGuideSearchResultsList, UserGuideSearchSidebar },
  props: {
    searchQuery: {
      type: String,
      required: true
    }
  },
  data() {
    return { searchResults: [] };
  },
  computed: {
    searchResultsCounter() {
      return this.searchResults.length;
    }
  },
  async mounted() {
    this.searchResults = await this.searchByQuery(this.searchQuery);
  },
  methods: {
    async searchByQuery(searchQuery = '') {
      const searchString = searchQuery.toLowerCase();

      const results = Object.values(USER_GUIDE_SEARCH).reduce((searchResults, article) => {
        const { name: articleName, title: articleTitle, subsections } = article;
        const translatedArticleTitle = this.$t(`userGuide.title.${articleTitle}`);

        const subsectionsSearchResults = subsections.reduce((subsectionsResults, subsection) => {
          const { title: subsectionTitle, subtitles } = subsection;
          const translatedSubsectionTitle = this.$t(`userGuide.title.${subsectionTitle}`);

          if (translatedSubsectionTitle.toLowerCase().includes(searchString)) {
            subsectionsResults.push(
              new SearchResult({
                articleName,
                firstTitle: translatedSubsectionTitle,
                secondTitle: translatedArticleTitle,
                searchLevel: 2,
                scrollId: subsectionTitle
              })
            );
          }

          if (!subtitles) {
            return subsectionsResults;
          }

          subtitles.forEach(subtitle => {
            const translatedSubtitle = this.$t(`userGuide.title.${subtitle}`);
            const isSubtitleMatchedSearch = translatedSubtitle.toLowerCase().includes(searchString);

            if (!isSubtitleMatchedSearch) {
              return;
            }

            subsectionsResults.push(
              new SearchResult({
                articleName,
                firstTitle: translatedSubtitle,
                secondTitle: translatedSubsectionTitle,
                thirdTitle: translatedArticleTitle,
                searchLevel: 3,
                scrollId: subtitle
              })
            );
          });

          return subsectionsResults;
        }, []);

        const articleLevelSearchResults = [...searchResults, ...subsectionsSearchResults];

        if (translatedArticleTitle.toLowerCase().includes(searchString)) {
          articleLevelSearchResults.push(
            new SearchResult({
              articleName,
              firstTitle: translatedArticleTitle,
              searchLevel: 1,
              scrollId: articleTitle
            })
          );
        }

        return articleLevelSearchResults;
      }, []);

      results.sort(
        ({ searchLevel: firstSearchLevel }, { searchLevel: secondSearchLevel }) =>
          secondSearchLevel - firstSearchLevel
      );

      return results;
    }
  }
};
</script>

<style lang="scss" scoped></style>
