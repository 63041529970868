export default class SearchResult {
  constructor({ articleName, firstTitle, secondTitle, thirdTitle, searchLevel, scrollId }) {
    this.articleName = articleName;
    this.firstTitle = firstTitle;
    this.secondTitle = secondTitle;
    this.thirdTitle = thirdTitle;
    this.searchLevel = searchLevel;
    this.scrollId = scrollId;
  }
}
