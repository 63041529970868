<template>
  <div class="user-guide-search-result flex">
    <div class="user-guide-search-result__icon flex--center--center">
      <span class="custom-icon" :class="searchResultIcon"></span>
    </div>
    <div class="user-guide-search-result__sections result-sections">
      <div class="result-sections__first-level pointer" @click="goToFirstLevelSection">
        {{ searchResult.firstTitle }}
      </div>
      <div v-if="searchResult.secondTitle" class="result-sections__second-level cursor-default">
        {{ searchResult.secondTitle }}
      </div>
      <div v-if="searchResult.thirdTitle" class="result-sections__third-level cursor-default">
        {{ searchResult.thirdTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import SearchResult from '@/modules/dashboard/components/user-guide/search/SearchResult';

import { ARTICLE_COMPONENT_CONFIG } from '@/modules/dashboard/api/user-guide';

export default {
  name: 'UserGuideSearchResult',
  props: {
    searchResult: {
      type: SearchResult,
      default: () => ({})
    }
  },
  computed: {
    searchResultIcon() {
      return ARTICLE_COMPONENT_CONFIG[this.searchResult.articleName].icon;
    }
  },
  methods: {
    goToFirstLevelSection() {
      const { articleName, scrollId } = this.searchResult;

      if (!articleName || !ARTICLE_COMPONENT_CONFIG[articleName].name) {
        return;
      }

      this.$router.push({
        name: 'ArticleSection',
        params: {
          articleName,
          scrollId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';

.user-guide-search-result {
  min-height: 70px;
  margin-bottom: 20px;

  &__icon {
    width: 20px;
    height: 20px;

    margin-right: 10px;
  }
}

.result-sections {
  &__first-level,
  &__second-level,
  &__third-level {
    font-weight: bold;
  }

  &__first-level,
  &__second-level {
    margin-bottom: 5px;
  }

  &__second-level,
  &__third-level {
    font-size: 14px;
    line-height: 19px;
  }

  &__first-level {
    font-size: 18px;
    line-height: 22px;
    color: $links-color;
  }

  &__second-level {
    color: $text-color;
  }

  &__third-level {
    color: $grey-color;
  }
}
</style>
