<template>
  <aside ref="sidebar" class="sidebar">
    <div class="sidebar__back-button pointer" @click="goToUserGuideMainPage">
      <div class="back-button__icon custom-icon icon-arrow-long-left mr7"></div>
      <span class="back-button__text">{{ $t('userGuide.label.userGuideHome') }}</span>
    </div>

    <div class="sidebar__found-results found-results">
      <i18n path="userGuide.label.searchResults" tag="span">
        <span>
          {{ searchResultsCounter }}
        </span>
        <span class="found-results__search-query mx-3">
          {{ searchQuery }}
        </span>
      </i18n>
    </div>
  </aside>
</template>

<script>
import userGuideSidebarMixin from '@/modules/dashboard/components/mixins/userGuideSidebarMixin';

export default {
  name: 'UserGuideSearchSidebar',
  mixins: [userGuideSidebarMixin],
  props: {
    searchResultsCounter: {
      type: Number,
      default: 0
    },
    searchQuery: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/user-guide-sidebar';

.sidebar {
  &__found-results {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    color: $text-color;
  }
}

.found-results {
  &__search-query {
    color: $active;
  }
}

@media (max-width: 767px) {
  .sidebar {
    margin-bottom: 15px;
  }
}
</style>
